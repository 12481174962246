import { Controller } from 'stimulus'
import responsiveWatch from 'responsive-watch'
import breakpoints from '../config/breakpoints'
import { Swiper, Pagination, A11y } from '../../../../../node_modules/swiper/js/swiper.esm.js'
import $$ from 'double-dollar'

Swiper.use([Pagination, A11y])

export default class extends Controller {
  static targets = [
    'RootCardNumber',
    'RootCardDate',
    'RootCardTitle',
    'RootCardCopy',
    'RootCardBtn',
  ]

  connect() {
    const watchers = responsiveWatch(
      {
        sizes: breakpoints,
      },
      status => {
        if (status.lte.m) {
          const element = this.element.querySelector('.swiper-container')
          this.mySwiper = new Swiper(element, {
            speed: 400,
            watchOverflow: true,
            slidesPerView: 'auto',
            spaceBetween: 20,
            grabCursor: true,
            watchSlidesVisibility: true,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
            simulateTouch: true,
            preventClicks: true,
            autoHeight: true,
            pagination: {
              el: '.carousel-pagination--featuredContentCard',
              clickable: true,
              dynamicBullets: false,
            },
            a11y: {
              prevSlideMessage: 'Previous slide',
              nextSlideMessage: 'Next slide',
            },
            init: false,
          })
          this.mySwiper.init()
        }
      }
    )
  }

  showCardDetails(event) {
    this.cardBtn = event.currentTarget
      .querySelector('.cardBtn')
      .getAttribute('href')
    var featuredContentCard = this.element.querySelectorAll(
      '.featuredContentCard'
    )
    Array.from(featuredContentCard).forEach(card => {
      card.classList.remove('is-active')
    })
    event.currentTarget.classList.add('is-active')
    this.RootCardNumberTarget.innerText = event.currentTarget.querySelector(
      '.featuredContentCard-number'
    ).innerText
    this.RootCardDateTarget.innerText = event.currentTarget.querySelector(
      '.featuredContentCard-date'
    ).innerText
    this.RootCardTitleTarget.innerText = event.currentTarget.querySelector(
      '.featuredContentCard-title'
    ).innerText
    this.RootCardCopyTarget.innerText = event.currentTarget.querySelector(
      'p'
    ).innerText
    this.RootCardBtnTarget.setAttribute('href', this.cardBtn)
  }
}

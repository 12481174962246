import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {}

  goTojobList() {
    document.getElementById('jobList').scrollIntoView({
      behavior: 'smooth',
    })
  }
}

/*global CookieConsent:readonly*/
const addCookieBotListeners = (props) => {
  window.addEventListener(
    'CookiebotOnDecline',
    function () {
      if (CookieConsent.changed) {
        props.OnDecline()
      }
    },
    false
  )
  window.addEventListener(
    'CookiebotOnAccept',
    function () {
      if (CookieConsent.changed) {
        props.OnAccept()
      }
    },
    false
  )
  return !props.listenerFlag
}

export { addCookieBotListeners as default }
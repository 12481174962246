import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  initialize() {
    this.targetID = this.data.get('target')
    this.targetElement = document.querySelector(`#${this.targetID}`)

    // If no target element, disable the modal
    if (this.targetElement === null) {
      this.element.setAttribute('disabled', true)
      return
    }

    this.size = 'large'
    this.type = 'standard'
    if (this.data.has('size')) {
      this.size = this.data.get('size')
    }

    if (this.data.has('type')) {
      this.type = this.data.get('type')
    }

    if (this.targetElement.innerHTML.indexOf('modal') === -1) {
      this.targetElement.innerHTML = this.handleRender(
        this.targetElement.innerHTML
      )
    }

    MicroModal.init({
      // onShow: modal => console.info(`${modal.id} is shown`),
      onClose: () => {
        document.querySelector('html').classList.remove('no-scroll')
        if (this.type === 'media') {
          var event = new Event('Video:pause')
          window.dispatchEvent(event)
        }
      },
      openTrigger: 'data-modal-target',
      debugMode: false,
    })
    this.element.addEventListener('click', this.handleShow.bind(this))
  }

  handleShow(e) {
    e.preventDefault()
    document.querySelector('html').classList.add('no-scroll')
    MicroModal.show(`modal-${this.targetID}`)
  }

  handleRender(content) {
    return `<div class="modal" id="modal-${this.targetID}" aria-hidden="true">
  <div class="modal-overlay" tabindex="-1" data-micromodal-close>
    <div class="modal-container modal-container--${
      this.size
    } modal-container--${this.type}"
      role="dialog" aria-modal="true">
        <main class="modal-content" id="modal-${this.targetID}-content">
          ${content}
        </main>
      <button class="modal-close" aria-label="Close modal" data-micromodal-close></button>
    </div>
  </div>
</div>`
  }
}

import { Controller } from 'stimulus'
import $$ from 'double-dollar'

export default class extends Controller {
  initialize() {
    this.hideSelectContent()
    this.hideContentOnOutsideClick()
  }

  selectChange(event) {
    this.hideSelectContent()
    this.element
      .querySelector(`#${event.target.value}`)
      .classList.remove('is-hidden')
  }

  hideContentOnOutsideClick() {
    document.addEventListener('click', function(event) {
      if (event.target.closest('.contactUsCard-selectBox')) {
        return false
      } else {
        document.querySelector('.contactUsCard-select').value = '--'
        $$('.contactUsCard-selectContent').forEach(item => {
          item.classList.add('is-hidden')
        })
      }
    })
  }

  hideSelectContent() {
    $$('.contactUsCard-selectContent', this.element).forEach(item => {
      item.classList.add('is-hidden')
    })
  }
}

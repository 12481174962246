import { Controller } from 'stimulus'
import isLeapYear from 'date-fns/isleapyear'

const API_PATH = window.CM_CONFIG.apis.swagger.endpoint
export default class extends Controller {
  static targets = [
    'EstimatedRefund',
    'DaysSickLeaveRemaining',
    'DaysSickLeaveRemainingFullPay',
    'DaysSickLeaveRemainingHalfPay',
  ]

  initialize() {
    this.pageId = []
    this.showCurrentPage()
    this.SickPayDataList = this.element.querySelector(
      '.calculator-success-list'
    )
    this.calcNumber = this.element.querySelector('.form-input--calcNumber')
    this.loadingText = this.element.querySelector('.loading')
  }

  showCurrentPage() {
    let calcPages = this.element.querySelectorAll('.calculator-page')
    Array.from(calcPages).forEach((el, i) => {
      this.pageId.push(parseFloat(el.getAttribute('data-pageid')))
      if (this.page == this.pageId[i]) {
        el.classList.add('active-page')
      } else {
        el.classList.remove('active-page')
      }
    })
    this.hideIcon()
    this.updateProgress()
    this.submitCalcForm()
  }

  restartCalculator() {
    this.loadingText.classList.remove('is-hidden')
    if (this.SickPayDataList != null) {
      this.SickPayDataList.classList.add('is-hidden')
    }
    this.page = 1
    var Forms = this.element.querySelectorAll('form')
    var FormElements = this.element.querySelectorAll('input, select')
    var SubmitButtons = this.element.querySelectorAll('#calcSubmitButton')
    var ValidationClasses = this.element.querySelectorAll('.form-input')
    var errorMessageClasses = this.element.querySelectorAll('.status')
    Array.from(Forms).forEach((el, i) => {
      el.reset()
    })
    Array.from(FormElements).forEach((el, i) => {
      el.oninput = null
      el.onchange = null
      if (el.classList.contains('is-success')) {
        el.classList.remove('is-success')
      }
      if (el.classList.contains('is-error')) {
        el.classList.remove('is-error')
      }
    })
    Array.from(errorMessageClasses).forEach((el, i) => {
      el.style.display = 'none'
      if (el.classList.contains('status-success')) {
        el.classList.remove('status-success')
      }
      if (el.classList.contains('status-error')) {
        el.classList.remove('status-error')
      }
    })
  }

  get page() {
    return parseFloat(this.data.get('page'))
  }

  set page(value) {
    this.data.set('page', value)
    this.showCurrentPage()
  }

  submitCalcForm() {
    if (this.page == 7) {
      this.TaxRefundCalculator = document.getElementById('taxRefundCalculator')
      let TaxRefundRequestData = this.toJSONString(this.TaxRefundCalculator)

      this.EstimatedRefundTarget.classList.add('is-hidden')
      this.element.querySelector('.estimated-refund-title').classList.remove('is-hidden')
      this.element.querySelector('.loading').classList.remove('is-hidden')
      this.element.querySelector('.calculator-copy-success').classList.remove('is-hidden')
      this.element.querySelector('.loading--error').classList.add('is-hidden')

        fetch(`/forms/calculatetaxrefund`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ taxRefundRequest: TaxRefundRequestData })
        })
        .then(response => response.json())
        .then(data => {
          this.loadingText.classList.add('is-hidden')
          this.EstimatedRefundTarget.classList.remove('is-hidden')
          this.EstimatedRefundTarget.innerHTML =
            '<span>€</span>' + data.TotalRefundResult
          this.element.querySelector('.loading--error').classList.add('is-hidden')
        })
        .catch(err => {
          console.log(err)
          this.element.querySelector('.estimated-refund-title').classList.add('is-hidden')
          this.element.querySelector('.loading').classList.add('is-hidden')
          this.element.querySelector('.calculator-copy-success').classList.add('is-hidden')
          this.element.querySelector('.loading--error').classList.remove('is-hidden')
          this.element.querySelector('.loading--error').parentElement.style.borderBottom = '0 none';
        })

    }
  }

  submitSickPayCalcForm() {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.page = this.page + 1
      let SickPayCalculator = document.getElementById('sickPayCalculator')
      let SickPayRequestData = this.toJSONString(SickPayCalculator)

      this.element.querySelector('.loading--sickPay').classList.remove('is-hidden')
      this.element.querySelector('.loading--sickPayError').classList.add('is-hidden')

      fetch(`/forms/calculatesickpay`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sickPayRequest: SickPayRequestData })
      })
      .then(response => response.json())
      .then(data => {
        this.loadingText.classList.add('is-hidden')
        this.SickPayDataList.classList.remove('is-hidden')
        this.element.querySelector('.loading--sickPayError').classList.add('is-hidden')
        this.DaysSickLeaveRemainingTarget.innerText = data.DaysSickLeaveRemaining;
        this.DaysSickLeaveRemainingFullPayTarget.innerText = data.DaysSickLeaveRemainingFullPay;
        this.DaysSickLeaveRemainingHalfPayTarget.innerText = data.DaysSickLeaveRemainingHalfPay;
      })
      .catch(err => {
        console.log(err)
        this.element.querySelector('.loading--sickPay').classList.add('is-hidden')
        this.element.querySelector('.loading--sickPayError').classList.remove('is-hidden')
      })
    }
  }

  toJSONString(calcDivision) {
    var obj = {}
    var elements = calcDivision.querySelectorAll('input, select')
    for (var i = 0; i < elements.length; ++i) {
      var element = elements[i]
      var name = element.name
      var value
      if (element.type == 'number' || element.type == 'tel') {
        value = Number(element.value)
      } else if (element.type == 'radio' && element.checked == true) {
        if (element.value == 'true') {
          value = true
        } else if (element.value == 'false') {
          value = false
        } else value = element.value
      } else if (element.type == 'select-one') {
        value = element.value
      }

      if (name && value !== '' && value !== undefined && value !== null) {
        obj[name] = value
      }

      if (element.name == 'NumberOfStudents' && element.value == 0) {
        obj['NumberOfStudents'] = 0
      }
    }

    if (this.dateofbirth !== '') {
      obj['AvcDob'] = this.dateofbirth
    }

    return obj
  }

  nextPage() {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.page = parseInt(this.page)
      this.page++
      this.updateProgress()
    }
  }

  prevPage() {
    if (this.page == Math.floor(this.page)) {
      this.page--
      this.updateProgress()
    } else {
      this.page = parseFloat((this.page - 0.1).toFixed(1))
    }
  }

  nextSubPage() {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.page = parseFloat((this.page + 0.1).toFixed(1))
    }
  }

  Page2Next() {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      const subPageChoice = this.element.querySelector('.subPage-choice')
      if (subPageChoice.value == 'Married') {
        this.page = parseFloat((this.page + 0.1).toFixed(1))
      } else {
        this.page++
      }
    }
  }

  hideIcon() {
    if (
      this.page == 1 ||
      this.element.querySelector('.last-page').classList.contains('active-page')
    ) {
      this.element.querySelector('.back-icon').classList.add('is-hidden')
    } else {
      this.element.querySelector('.back-icon').classList.remove('is-hidden')
    }
  }

  Increment() {
    this.element.querySelector('.form-input--calcNumber').value =
      parseInt(this.calcNumber.value) + 1
  }

  Decrement() {
    if (parseInt(this.calcNumber.value) > 0) {
      this.element.querySelector('.form-input--calcNumber').value =
        parseInt(this.calcNumber.value) - 1
    } else {
      this.calcNumber.value = '0'
      this.element.querySelector('.form-input--calcNumber').value = parseInt(
        this.calcNumber.value
      )
    }
  }

  updateProgress() {
    const progress = this.element.querySelector('.progress-bar')
    if (progress !== null && progress !== undefined) {
      if (this.page == 1) {
        progress.style.width = '4%'
      }
      if (this.page == 2) {
        progress.style.width = '20%'
      }
      if (this.page == 3) {
        progress.style.width = '36%'
      }
      if (this.page == 4) {
        progress.style.width = '52%'
      }
      if (this.page == 5) {
        progress.style.width = '68%'
      }
      if (this.page == 6) {
        progress.style.width = '84%'
      }
      if (this.page == 7) {
        progress.style.width = '100%'
      }
    }
  }

  validateDate() {
    var date = this.element.querySelector('.calc--dd')
    var month = this.element.querySelector('.calc--mm')
    var year = this.element.querySelector('.calc--yyyy')

    //combine the date, month and year
    this.dateofbirth = month.value + '/' + date.value + '/' + year.value
    //check it's validity
    if (date.checkValidity() && month.checkValidity() && year.checkValidity()) {
      const regEx = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/
      const valid = regEx.test(this.dateofbirth)
      if (!valid) {
        this.element.querySelector('.date').classList.add('is-invalid')
        this.element.querySelector('.date').classList.remove('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.remove('is-hidden')
      } else if (
        valid &&
        month.value == 2 &&
        isLeapYear(this.dateofbirth) &&
        date.value >= 29
      ) {
        this.element.querySelector('.date').classList.remove('is-invalid')
        this.element.querySelector('.date').classList.add('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.add('is-hidden')
        this.nextSubPage()
      } else if (
        valid &&
        month.value == 2 &&
        !isLeapYear(this.dateofbirth) &&
        date.value > 28
      ) {
        this.element.querySelector('.date').classList.add('is-invalid')
        this.element.querySelector('.date').classList.remove('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.remove('is-hidden')
      } else {
        this.element.querySelector('.date').classList.remove('is-invalid')
        this.element.querySelector('.date').classList.add('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.add('is-hidden')
        this.nextSubPage()
      }
    }
  }

  validateNextPage() {
    if (this.page == 3) {
      if (document.getElementById('MedicalExpenseYes').checked == true) {
        this.nextSubPage()
        return false
      } else {
        this.nextPage()
        return false
      }
    }

    if (this.page == 3.2) {
      if (document.getElementById('GetRefundsYes').checked == true) {
        this.nextSubPage()
        return false
      } else {
        this.nextPage()
        return false
      }
    }

    if (this.page == 4) {
      if (document.getElementById('TutionFeesYes').checked == true) {
        this.nextSubPage()
        return false
      } else {
        this.nextPage()
        return false
      }
    }

    if (this.page == 5) {
      if (document.getElementById('SalayProtectionPayYes').checked == true) {
        this.nextSubPage()
        return false
      } else {
        this.nextPage()
        return false
      }
    }

    if (this.page == 6) {
      if (document.getElementById('AVCPayYes').checked == true) {
        this.nextSubPage()
        return false
      } else {
        this.nextPage()
        return false
      }
    }
  }
}

import ScrollWatcher from 'scroll-watcher'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    const targetSelector = this.data.get('target')
    const targetElement =
      targetSelector && document.querySelector(targetSelector)
    const scroll = new ScrollWatcher()
    if (targetSelector === '.subNavRoot') {
      var header = document.getElementById('subNavRoot')
      var sticky = header.offsetTop
      scroll.on('scrolling', () => {
        if (window.pageYOffset > sticky) {
          header.classList.add('subNavRoot--sticked')
        } else {
          header.classList.remove('subNavRoot--sticked')
        }
      })
    } else {
      scroll
        .watch(this.element)
        .on('enter', () => {
          if (targetElement !== null) {
            targetElement.classList.add('is-shown')
          }
        })
        .on('exit', () => {
          if (targetElement !== null) {
            targetElement.classList.remove('is-shown')
          }
        })
    }
  }
  setMainId() {
    document.querySelectorAll('section')[1].setAttribute('id', 'main')
  }
}

import { Controller } from 'stimulus'
import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
} from 'swiper/js/swiper.esm.js'
 
Swiper.use([Navigation, Pagination, A11y])

export default class extends Controller {
  static get targets() {
    return ['PrevButton', 'NextButton']
  }
  connect() {
    const element = this.element.querySelector('.swiper-container')
    this.paginationClass = this.data.get('pagination')
    this.swiper = new Swiper(element, {
      speed: 400,
      watchOverflow: true,
      slidesPerView: 'auto',
      spaceBetween: 30,
      grabCursor: true,
      slidesOffsetBefore: 30,
      slidesOffsetAfter: 30,
      simulateTouch: true,
      preventClicks: true,
      autoHeight: true,
      breakpoints: {
        // when window width is <= 480px
        1220: {
          slidesOffsetBefore: 30,
          slidesOffsetAfter: 30,
          spaceBetween: 30,
          freeMode: true,
        },
      },
      pagination: {
        el: this.paginationClass,
        clickable: true,
        dynamicBullets: true,
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      init: false,
    })

    this.swiper.on('init', () => {
      this.PrevButtonTarget.classList.add('is-disabled')
    })
    this.swiper.on('slideChange', this.slideChange.bind(this))
    this.swiper.on('reachBeginning', () => {
      this.PrevButtonTarget.classList.add('is-disabled')
    })
    this.swiper.on('reachEnd', () => {
      this.NextButtonTarget.classList.add('is-disabled')
    })

    const self = this;
    setTimeout(function () {
      self.swiper.init()

      self.PrevButtonTarget.addEventListener('click', () => {
        self.swiper.slidePrev()
      })

      self.NextButtonTarget.addEventListener('click', () => {
        self.swiper.slideNext()
      })

      if (self.swiper.slides.length < 3) {
        self.NextButtonTarget.classList.add('is-hidden')
        self.PrevButtonTarget.classList.add('is-hidden')
        self.element
          .querySelector('.carousel-pagination')
          .classList.add('is-hidden')
      }
    }, 1000)    
  }

  slideChange() {
    if (!this.swiper.isBeginning) {
      this.PrevButtonTarget.classList.remove('is-disabled')
    }

    if (!this.swiper.isEnd) {
      this.NextButtonTarget.classList.remove('is-disabled')
    }
  }
}

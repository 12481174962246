let API_PATH = window.CM_CONFIG.apis.zendesk.endpoint ? API_PATH = window.CM_CONFIG.apis.zendesk.endpoint : ''
let authToken = ''

const initFaq = () => {
  let subfolderPath = subfolderPath ? subfolderPath : ''
  fetch(subfolderPath + '/IntegrationService/chat/key/')
  .then(response => {
    if(response){
      return response.json();
    }
    throw new Error('Problem with faq init');
  })
  .then(data => {
    authToken = data;
  })
  .catch(err => {
    console.log(err);
  })
}

const getZendeskData = endpoint => {
  return fetch(`https://${API_PATH}.zendesk.com/${endpoint}`, {
    headers: {
      Authorization: `Basic ${authToken}`,
      'Content-Type': 'multipart/form-data; charset=UTF-8',
    },
  }).then(res => {
    if (res.status >= 400) {
      throw new Error('Bad response from server')
    }
    return res.json()
  })
}

export {getZendeskData as default, getZendeskData, initFaq, API_PATH }

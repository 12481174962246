import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.element.classList.add('blockClick')
    this.element.addEventListener('click', () => {
      window.location = this.element.querySelector('a').getAttribute('href')
    })
  }
}

import { Controller } from 'stimulus'
import $$ from 'double-dollar'

const API_PATH = window.CM_CONFIG.apis.swagger.endpoint

export default class extends Controller {
  static targets = ['Message', 'Button']

  initialize() { }

  selectAll(event) {
    var select_all = this.element.querySelector("input[id^='All']")
    var checkboxes = this.element.querySelectorAll('.genericCheckBox')

    select_all.addEventListener('change', function (e) {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = select_all.checked
      }
    })
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].addEventListener('change', function (e) {
        if (this.checked == false) {
          select_all.checked = false
        }
        if (
          document.querySelectorAll('.genericCheckBox:checked').length ==
          checkboxes.length
        ) {
          select_all.checked = true
        }
      })
    }
  }

  Submit(event) {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.ButtonTarget.setAttribute('disabled', 'disabled')
      let formData = this.toJSONString(this.element)
      this.element.querySelector('.message-box').classList.remove('is-hidden')
      this.element
        .querySelector('.message-box')
        .classList.remove('error', 'success')
      this.element.querySelector('.message-box').classList.add('info')
      this.MessageTarget.innerText = 'Loading please wait...'

      fetch(`/forms/contactrequest`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          VerificationToken: $("[id=forgeryToken]").val(),
        },
        body: JSON.stringify({ contactRequest: formData })
      })
      .then(response => response.json())
      .then(data => {
        console.log('submit form');
        this.element.querySelector('.formtohide').classList.add('is-hidden')
        this.element
          .querySelector('.callbackForm-success')
          .classList.remove('is-hidden')
        this.element
          .querySelector('.callbackForm-success')
          .classList.add('success')
        if (data.Result.SuccessHeader1 != null) {
          this.element.querySelector(
            '.callbackForm-success .header'
          ).innerHTML =
            data.Result.SuccessHeader1
        }
        if (data.Result.SuccessHeader2 != null) {
          this.element.querySelector(
            '.callbackForm-success .callbackForm-success--copy h3'
          ).innerText =
            data.Result.SuccessHeader2
        }
        if (data.Result.SuccessHeader3 != null) {
          this.element.querySelector(
            '.callbackForm-success .callbackForm-success--paragraph p'
          ).innerText =
            data.Result.SuccessHeader3
        }
        this.element
          .querySelector('.callbackForm-success .success-btn')
          .setAttribute('href', 'tel:' + data.Result.SuccessCTA)
        this.element.querySelector(
          '.callbackForm-success .btn-inner span'
        ).innerText =
          data.Result.SuccessCTA

        this.ButtonTarget.removeAttribute('disabled')

        window.dataLayer.push({
          event: 'VirtualPageview',
          virtualPageURL: data.Result.VirtualPageURL,
          virtualPageTitle: data.Result.VirtualPageTitle,
        })
      })
      .catch(err => {
        console.log(err)
        this.element.querySelector('.message-box').classList.remove('info')
        this.element.querySelector('.message-box').classList.add('error')
        this.MessageTarget.innerText = 'Error occured!'
        this.ButtonTarget.removeAttribute('disabled')
      });
    }
  }

  toJSONString(data) {
    let obj = {}
    let elements = data.querySelectorAll('input, select, textarea')
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i]
      let name = element.name
      let value
      if (element.type == 'radio' || element.type == 'checkbox') {
        if (element.checked) {
          if (element.value === 'true') {
            value = true
          } else if (element.value === 'false') {
            value = false
          } else {
            value = element.value
          }
        }
      } else {
        if (element.parentElement.classList.contains('date')) {
          name = element.parentElement.children[3].name
          value = new Date(element.parentElement.children[3].value)
        } else {
          value = element.value.trim()
        }
      }
      if (element.hasAttribute('data-key') && element.checked) {
        obj[element.getAttribute('data-key')] = Boolean(element.value)
      }
      if (
        value !== '' &&
        value !== undefined &&
        value !== null &&
        !element.hasAttribute('data-key')
      ) {
        obj[name] = value
      }
    }
    if (obj.FirstName != undefined || obj.LastName != undefined) {
      obj['Name'] = obj.FirstName + ' ' + obj.LastName
      delete obj.FirstName
      delete obj.LastName
    }
    if (!obj.RequestType) {
      obj['RequestType'] = 'LEAD'
    }
    return obj
  }

  selectChange(event) {
    this.hideSelectContent()
    var productValue = event.target.value.toLowerCase()
    if (
      productValue.includes('car') ||
      productValue.includes('health') ||
      productValue.includes('home') ||
      productValue.includes('dental') ||
      productValue.includes('travel')
    ) {
      this.element
        .querySelector('.morning_evening')
        .classList.remove('is-hidden')
    } else {
      this.element.querySelector('.time_slot').classList.remove('is-hidden')
    }
  }

  hideSelectContent() {
    $$('.selectContent', this.element).forEach(item => {
      item.classList.add('is-hidden')
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['Title', 'Body']
  initialize() {
    this.BodyTargets.forEach(item => {
      item.setAttribute('aria-hidden', 'true')
    })
  }
  handleChange(event) {
    event.currentTarget.classList.toggle('is-active')
    event.currentTarget.nextElementSibling.classList.toggle(
      'accordion-body--show'
    )
    event.currentTarget.nextElementSibling.classList.toggle(
      'accordion-body--hide'
    )
    let accordionBody = event.currentTarget.nextElementSibling
    if (accordionBody.attributes['aria-hidden'].value == 'true') {
      accordionBody.setAttribute('aria-hidden', 'false')
    } else {
      accordionBody.setAttribute('aria-hidden', 'true')
    }
  }
}

import { Controller } from 'stimulus'
import responsiveWatch from 'responsive-watch'
import breakpoints from '../config/breakpoints'

export default class extends Controller {
  static get targets() {
    return [
      'NarrowNavRoot',
      'Container',
      'PrimaryNavBtn',
      'SubSection',
      'SubSectionBackBtn',
      'BurgerBtn',
    ]
  }

  connect() {
    // eslint-disable-next-line no-unused-vars
    const watchers = responsiveWatch(
      {
        sizes: breakpoints,
      },
      status => {
        if (status.gte.l) {
          this.hideSubSection()
          this.element.classList.remove('is-active')
          this.NarrowNavRootTarget.classList.remove('is-active')
          this.BurgerBtnTarget.classList.remove('is-active')
        }
      }
    )
  }

  showSubSection(event) {
    const subSection = event.target.nextElementSibling
    const subSectionHeight = subSection.scrollHeight
    subSection.classList.add('is-active')
    this.ContainerTarget.classList.add('is-active')
    this.ContainerTarget.style.height = `${subSectionHeight}px`
  }

  hideSubSection() {
    this.ContainerTarget.classList.remove('is-active')
    this.ContainerTarget.style.height = `auto`
    this.SubSectionTargets.forEach(item => item.classList.remove('is-active'))
  }

  handleToggleNavigation() {
    this.element.classList.toggle('is-active')
    this.NarrowNavRootTarget.classList.toggle('is-active')
    this.BurgerBtnTarget.classList.toggle('is-active')
    this.hideSubSection()
  }
}

import { Controller } from 'stimulus'
import $$ from 'double-dollar'

export default class extends Controller {
  initialize() {
    this.group = this.data.get('group')
    this.children = this.element.querySelectorAll(this.data.get('targets'))
    this.limit = parseInt(this.data.get('limit'), 10) - 1

    if (this.children.length > this.limit) {
      this.renderShowMoreButton()
      this.hideMore()
      this.element
        .querySelector('.showMoreBtn')
        .addEventListener('click', this.showMore.bind(this))
    }
  }

  renderShowMoreButton() {
    /* Changing this functionality because of IE issue */
    // const button = `<div class="showMoreUI"><button class="showMoreBtn btn btn--secondary">
    //   <span class="btn-inner">
    //     <svg class="icon icon--btn" role="img">
    //       <use xlink:href="../UI/dist/icons.svg#plus"></use>
    //     </svg>
    //     <span>Show more</span>
    //   </span>
    // </button></div>`
    // this.element.innerHTML += button
    this.element.querySelector('.showMoreUI').classList.remove('is-hidden')
  }

  hideMore() {
    $$(this.data.get('targets'), this.element).forEach((item, index) => {
      if (index > this.limit) {
        item.classList.add('is-hidden')
      }
    })
  }

  showMore(e) {
    e.currentTarget.classList.add('is-hidden')
    $$(this.data.get('targets'), this.element).forEach(item => {
      item.classList.remove('is-hidden')
    })
  }
}

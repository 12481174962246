const getRoute = () => {
  let location = window.location.pathname
  let hostname =
    window.location.hostname === 'localhost'
      ? 'www.cornmarket.ie'
      : window.location.hostname
  let formattedLocation = location.replace(/\//g, '_')

  return hostname + formattedLocation  
}

export { getRoute as default, getRoute }

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'iconCardCopy',
    'iconCardTitle',
    'contentCardTitle',
    'contentCardCopy',
    'Chip',
  ]

  initialize() {
    this.setHeight(this.iconCardCopyTargets)
    this.setHeight(this.iconCardTitleTargets)
    this.setHeight(this.contentCardTitleTargets)
    this.setHeight(this.contentCardCopyTargets)
    this.setHeight(this.ChipTargets)
  }

  setHeight(targetArray) {
    var maxHeight = []
    if (targetArray.length >= 1) {
      Array.from(targetArray).forEach(el => {
        maxHeight = maxHeight > el.scrollHeight ? maxHeight : el.scrollHeight
      })
      Array.from(targetArray).forEach(el => {
        setTimeout(function () {
          el.style.minHeight = maxHeight + 'px'
        }, 1000)
      })
    }
  }
}

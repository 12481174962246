/**
 * Usage:
 * import pointerEvents from './pointerEvents'
 * window.addEventListener(pointerEvents.enter, () => {}, false)
 */

export default (window.PointerEvent
  ? {
      end: 'pointerup',
      enter: 'pointerenter',
      leave: 'pointerleave',
      over: 'pointerover',
    }
  : {
      end: 'touchend',
      enter: 'mouseenter',
      leave: 'mouseleave',
      over: 'mouseover',
    })

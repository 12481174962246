import { Controller } from 'stimulus'
import addDays from 'date-fns/adddays'

export default class extends Controller {
  initialize() {
    this.checkCookie()
  }

  setCookie() {
    this.element.classList.add('is-hidden')
    const date = addDays(new Date(), this.data.get('expiry'))
    document.cookie = 'accepted=yes;expires=' + date.toUTCString() + ';path=/'
  }

  checkCookie() {
    const cookie = this.getCookie()
    if (cookie.accepted != '' && cookie.accepted === 'yes') {
      this.element.classList.add('is-hidden')
    } else {
      this.element.classList.remove('is-hidden')
    }
  }

  getCookie() {
    const pairs = document.cookie.split(';')
    const cookies = {}
    pairs.forEach((pair, i) => {
      pair = pairs[i].split('=')
      cookies[(pair[0] + '').trim()] = unescape(pair[1])
    })
    return cookies
  }
}

import { Controller } from 'stimulus'
import $$ from 'double-dollar'
import responsiveWatch from 'responsive-watch'
import breakpoints from '../config/breakpoints'
import { Swiper, Pagination, A11y } from '../../../../../node_modules/swiper/js/swiper.esm.js'

const API_PATH = window.CM_CONFIG.apis.swagger.endpoint

Swiper.use([Pagination, A11y])

export default class extends Controller {
  static targets = [
    'ProblemPhase',
    'SchedulePhase',
    'DetailsPhase',
    'Message',
    'SuccessForm',
    'FormContentContainer',
    'Button'
  ]
  initialize() {
    this.FormContainer = this.element
    this.ProblemPhaseTarget.classList.add('is-active')
    this.element
      .querySelector('.carousel-pagination--callbackForm')
      .classList.remove('is-hidden')
    this.connect()

    document.getElementById('problemForm').reset()
    document.getElementById('scheduleForm').reset()
    document.getElementById('informationForm').reset()
    this.SuccessFormTarget.classList.add('is-hidden')
  }

  connect() {
    const watchers = responsiveWatch(
      {
        sizes: breakpoints,
      },
      status => {
        if (status.lte.m) {
          const element = this.element.querySelector('.swiper-container')
          this.mySwiper = new Swiper(element, {
            speed: 400,
            watchOverflow: true,
            slidesPerView: 'auto',
            spaceBetween: 0,
            grabCursor: true,
            watchSlidesVisibility: true,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
            simulateTouch: true,
            preventClicks: true,
            autoHeight: true,
            pagination: {
              el: '.carousel-pagination',
              clickable: true,
              dynamicBullets: true
            },
            a11y: {
              prevSlideMessage: 'Previous slide',
              nextSlideMessage: 'Next slide',
            },
            init: false,
          })
          
        }
      }
    )
  }

  showForm() {
    document.getElementById('problemForm').reset()
    document.getElementById('scheduleForm').reset()
    document.getElementById('informationForm').reset()
    this.SuccessFormTarget.classList.add('is-hidden')

    let generalEnquiryFormContainerClassList = document.getElementById(
      'generalEnquiryFormContainer'
    ).classList
    let callbackFormClassList = document.getElementById('callbackForm')
      .classList
    if (generalEnquiryFormContainerClassList.contains != 'is-hidden') {
      generalEnquiryFormContainerClassList.add('is-hidden')
      callbackFormClassList.remove('generalEnquiryForm-container')
    }

    document.getElementById('callbackForm').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })

    this.FormContainer.classList.add('callbackForm-container')
    this.FormContentContainerTarget.classList.remove('is-hidden')
    if (
      this.element
        .querySelector('.form-container')
        .classList.contains('is-hidden')
    ) {
      this.element
        .querySelector('.form-container')
        .classList.remove('is-hidden')
      this.SuccessFormTarget.classList.add('is-hidden')
    }

    this.mySwiper.init();
  }

  hideForm() {
    this.FormContainer.classList.remove('callbackForm-container')
    this.FormContentContainerTarget.classList.add('is-hidden')
    document.getElementById('problemForm').reset()
    document.getElementById('scheduleForm').reset()
    document.getElementById('informationForm').reset()
    this.element.querySelector('.message-box').classList.add('is-hidden')
    this.SuccessFormTarget.classList.add('is-hidden')
    var FormElements = this.element.querySelectorAll('input, select, textarea')
    var errorMessageClasses = this.element.querySelectorAll('.status')
    Array.from(FormElements).forEach((el, i) => {
      FormElements[i].oninput = null
      FormElements[i].onchange = null
      if (FormElements[i].classList.contains('is-success')) {
        FormElements[i].classList.remove('is-success')
      }
      if (FormElements[i].classList.contains('is-error')) {
        FormElements[i].classList.remove('is-error')
      }
    })
    Array.from(errorMessageClasses).forEach((el, i) => {
      errorMessageClasses[i].style.display = 'none'
      if (errorMessageClasses[i].classList.contains('status-success')) {
        errorMessageClasses[i].classList.remove('status-success')
      }
      if (errorMessageClasses[i].classList.contains('status-error')) {
        errorMessageClasses[i].classList.remove('status-error')
      }
    })
    this.SchedulePhaseTarget.classList.remove('is-active')
    this.SchedulePhaseTarget.classList.remove('is-visited')
    this.DetailsPhaseTarget.classList.remove('is-active')
    this.DetailsPhaseTarget.classList.remove('is-visited')
    this.ProblemPhaseTarget.classList.remove('is-visited')
  }

  SubmitProblemForm(event) {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.ProblemPhaseTarget.classList.add('is-visited')
      this.SchedulePhaseTarget.classList.add('is-active')
      this.mySwiper.slideNext()
    }
  }

  SubmitScheduleForm(event) {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.SchedulePhaseTarget.classList.add('is-visited')
      this.DetailsPhaseTarget.classList.add('is-active')
      this.mySwiper.slideNext()
    }
  }

  SubmitDetailsForm(event) {
    if (event.currentTarget.hasAttribute('disabled') == false) {
      this.ButtonTarget.setAttribute('disabled', 'disabled')
      this.DetailsPhaseTarget.classList.add('is-visited')
      this.callbackFormContainer = document.getElementById(
        'callbackFormContainer'
      )

      let CallbackFormData = this.toJSONString(this.callbackFormContainer)
      this.element.querySelector('.message-box').classList.remove('is-hidden')
      this.element
        .querySelector('.message-box')
        .classList.remove('error', 'success')
      this.element.querySelector('.message-box').classList.add('info')
      this.MessageTarget.innerText = 'Loading please wait...'

      fetch(`/forms/contactrequest`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          VerificationToken: $("[id=forgeryToken]").val(),
        },
        body: JSON.stringify({ contactRequest: CallbackFormData })
      })
      .then(response => {
        console.log('from response ',response)
        return response.json()
      })
      .then(data => {
        console.log('Json then ',data);
        this.element
          .querySelector('.form-container')
          .classList.add('is-hidden')
        document.getElementById('callbackForm').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        })
        this.SuccessFormTarget.classList.remove('is-hidden')
        this.SchedulePhaseTarget.classList.remove('is-active')
        this.SchedulePhaseTarget.classList.remove('is-visited')
        this.DetailsPhaseTarget.classList.remove('is-active')
        this.DetailsPhaseTarget.classList.remove('is-visited')
        this.ProblemPhaseTarget.classList.remove('is-visited')
        this.ProblemPhaseTarget.classList.add('is-active')

        this.element.querySelector(
          '.callbackForm-success .header'
        ).innerHTML = data.Result.SuccessHeader1
        this.element.querySelector(
          '.callbackForm-success .callbackForm-success--copy h3'
        ).innerText = data.Result.SuccessHeader2
        this.element.querySelector(
          '.callbackForm-success .callbackForm-success--paragraph p'
        ).innerText = data.Result.SuccessHeader3
        this.element
          .querySelector('.callbackForm-success .success-btn')
          .setAttribute('href', 'tel:' + data.Result.SuccessCTA)
        this.element.querySelector(
          '.callbackForm-success .btn-inner span'
        ).innerText = data.Result.SuccessCTA

        this.ButtonTarget.removeAttribute('disabled')

        window.dataLayer.push({
          event: 'VirtualPageview',
          virtualPageURL: data.Result.VirtualPageURL,
          virtualPageTitle: data.Result.VirtualPageTitle,
        })
      })
      .catch(err => {
        console.log(err)
        this.element.querySelector('.message-box').classList.remove('info')
        this.element.querySelector('.message-box').classList.add('error')
        this.MessageTarget.innerText = 'Error occured!'
        this.ButtonTarget.removeAttribute('disabled')
      })
    }
  }

  toJSONString(callbackFormDivision) {
    var obj = {}
    var elements = callbackFormDivision.querySelectorAll(
      'input, select, textarea'
    )
    for (var i = 0; i < elements.length; ++i) {
      var element = elements[i]
      var name = element.name
      var value
      if (element.type == 'radio' || element.type == 'checkbox') {
        if (element.checked) {
          if (element.value === 'true') {
            value = true
          } else if (element.value === 'false') {
            value = false
          } else {
            value = element.value
          }
        }
      } else {
        value = element.value.trim()
      }

      if (name && value !== '' && value !== undefined && value !== null) {
        obj[name] = value
      }
    }
    return obj
  }

  selectChange(event) {
    this.hideSelectContent()
    var productValue = event.target.value.toLowerCase()
    if (
      productValue.indexOf('car') != -1 ||
      productValue.indexOf('health') != -1 ||
      productValue.indexOf('home') != -1 ||
      productValue.indexOf('dental') != -1 ||
      productValue.indexOf('travel') != -1
    ) {
      this.element
        .querySelector('#callbackFormContainer .morning_evening')
        .classList.remove('is-hidden')
    } else {
      this.element
        .querySelector('#callbackFormContainer .time_slot')
        .classList.remove('is-hidden')
    }
  }

  hideSelectContent() {
    $$('#callbackFormContainer .selectContent', this.element).forEach(item => {
      item.classList.add('is-hidden')
    })
  }
}

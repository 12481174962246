import { Controller } from 'stimulus'
import PointerEvents from '../utils/pointerEvents'

const timeout = 50
const selectorOpenTimeout = 5000

export default class extends Controller {
  static get targets() {
    return [
      'ButtonWide',
      'DrpdwnRoot',
      'DrpdwnContainer',
      'DrpdwnSection',
      'DrpdwnBg',
    ]
  }

  connect() {
    if (this.ButtonWideTargets.length) {
      this.ButtonWideTargets.forEach(item => {
        item.addEventListener(
          'click',
          event => {
            event.preventDefault()
            event.stopPropagation()
          },
          true
        )

        // On mouse/pointer enter
        item.addEventListener(PointerEvents.enter, event => {
          if (event.pointerType !== 'touch') {
            this.data.set(
              'activeItem',
              event.target.getAttribute('data-dropdown-source')
            )
            this.data.set('globalBtnOver', true)
            this.showDropdown()

            //Remove selector union class if it is open
            document
              .querySelector('.mastheadRoot')
              .classList.remove('mastheadRoot-active')
            document
              .querySelector('.dropdownRoot')
              .classList.remove('selector-union-active-transition')
            document
              .querySelector('.dropdownSection .unionSearchRoot')
              .parentNode.classList.remove('selector-union-active')
            document
              .querySelector('.dropdownSection .unionSearchRoot')
              .parentNode.setAttribute('aria-hidden', 'true')
            document
              .querySelector('.common-search-container')
              .classList.remove('is-hidden')
            document
              .querySelector('.selector-dataWrapper')
              .classList.remove('is-active')
            document
              .querySelector('.selector-results')
              .setAttribute('aria-hidden', 'true')
          }
        })

        // On mouse/pointer leave
        item.addEventListener(PointerEvents.leave, event => {
          if (event.pointerType !== 'touch') {
            this.hideDropdown()
          }
        })

        // When keyboard is being used
        item.addEventListener(
          'focusin',
          event => {
            this.data.set(
              'activeItem',
              event.target.getAttribute('data-dropdown-source')
            )
            this.data.set('globalBtnOver', true)
            this.showDropdown()
          },
          false
        )

        item.addEventListener(
          'focusout',
          () => {
            this.hideDropdown.bind(this)
          },
          false
        )

        item.addEventListener('touchend', event => {
          event.preventDefault()
          event.stopPropagation()
          this.hideDropdown()
        })

        // item.addEventListener(PointerEvents.end, event => {
        //   event.preventDefault()
        //   event.stopPropagation()
        //   this.toggleDropdown(item)
        // })

        // when to show/hide the DrpdwnContainer
        this.DrpdwnContainerTarget.addEventListener(
          PointerEvents.end,
          event => {
            event.stopPropagation()
          }
        )

        this.DrpdwnContainerTarget.addEventListener(
          PointerEvents.enter,
          event => {
            if (event.pointerType !== 'touch') {
              this.showDropdown()
            }
          }
        )

        this.DrpdwnContainerTarget.addEventListener(
          PointerEvents.leave,
          event => {
            if (event.pointerType !== 'touch') {
              if (window.SelectorOpen) {
                this.selectorHideDropdownTimeout = window.setTimeout(() => {
                  window.SelectorOpen = false
                }, selectorOpenTimeout)

                const drpdwnRootHandler = event => {
                  if (event.target === this.DrpdwnRootTarget) {
                    this.hideDropdown()

                    this.DrpdwnRootTarget.removeEventListener(
                      'click',
                      drpdwnRootHandler.bind(this),
                      false
                    )
                  }
                }

                this.DrpdwnRootTarget.addEventListener(
                  'click',
                  drpdwnRootHandler.bind(this),
                  false
                )
                return
              }

              this.hideDropdown()
            }
          }
        )
      })
    }
  }

  showDropdown() {
    const activeItemId = this.data.get('activeItem')
    const activeNavButtonElement = this.element.querySelector(
      `.navBtn[data-dropdown-source=${activeItemId}]`
    )

    this.element.classList.add('is-active')
    this.DrpdwnRootTarget.classList.add('is-active')

    // Remove old classes
    this.DrpdwnSectionTargets.forEach(item =>
      item.classList.remove('is-active')
    )
    this.ButtonWideTargets.forEach(item => {
      item.classList.remove('is-over')
      item.setAttribute('aria-expanded', 'false')
    })

    activeNavButtonElement.classList.add('is-over')
    activeNavButtonElement.setAttribute('aria-expanded', 'true')
    const activeDropdownRect = activeNavButtonElement.getBoundingClientRect()
    const centrePosition = Math.round(
      activeNavButtonElement.offsetLeft + activeDropdownRect.width / 2
    )

    // Show the correct dropdown
    const targetContainer = this.element.querySelector(
      `[data-dropdown-target="${activeItemId}"]`
    )
    const targetContainerRect = targetContainer.getBoundingClientRect()
    targetContainer.setAttribute('aria-hidden', 'false')
    targetContainer.classList.add('is-active')

    clearTimeout(this.disableTransitionTimeout)
    this.enableTransitionTimeout = window.setTimeout(() => {
      this.DrpdwnRootTarget.classList.remove('no-transition')
    }, timeout)

    let halfDropdownWidth
    if (targetContainer.classList.contains('dropdownSection--thin')) {
      halfDropdownWidth = 160
    } else {
      halfDropdownWidth = 400
    }

    const xPos = centrePosition - halfDropdownWidth
    const xPosCorrected = xPos < 20 ? 20 : xPos

    targetContainer.style.transform = `translateX(${xPosCorrected}px)`

    this.DrpdwnBgTarget.style.transform = `translateX(${xPosCorrected}px)`
    this.DrpdwnBgTarget.style.width = `${targetContainerRect.width}px`
    this.DrpdwnBgTarget.style.height = `${targetContainerRect.height}px`
  }

  hideDropdown() {
    if (this.data.get('globalBtnOver')) {
      clearTimeout(this.enableTransitionTimeout)
      this.disableTransitionTimeout = window.setTimeout(() => {
        this.DrpdwnRootTarget.classList.add('no-transition')
      }, timeout)

      this.element.classList.remove('is-active')
      this.DrpdwnRootTarget.classList.remove('is-active')
      this.ButtonWideTargets.forEach(item => {
        item.classList.remove('is-over')
        item.setAttribute('aria-expanded', 'false')
      })

      this.DrpdwnContainerTarget.classList.remove('is-active')

      this.DrpdwnSectionTargets.forEach(item => {
        item.classList.remove('is-active')
        item.setAttribute('aria-hidden', 'true')
      })
    }
  }

  toggleDropdown(item) {
    const activeItemId = this.data.get('activeItem')
    const activeNavButtonElement = this.element.querySelector(
      `.navBtn[data-dropdown-source=${activeItemId}]`
    )
    activeNavButtonElement === item ? this.hideDropdown() : this.showDropdown()
  }
}

let API_PATH = window.CM_CONFIG.apis.autoaddress.endpoint ? window.CM_CONFIG.apis.autoaddress.endpoint : ''
let API_KEY = ''

const intAutoAddress =() => {
  let subfolderPath = subfolderPath ? subfolderPath : ''
  fetch(subfolderPath + '/IntegrationService/auto-address/key/')
        .then(response => {
          if(response.ok){
            return response.json()
          }
          throw new Error('Issue with auto address init');
        })
        .then(data => {
          API_KEY = data;
        })
        .catch(err => {
          console.log(err);
      });
}

const addressSearch = (search, limit = '-1') => {
  return fetch(
    `${API_PATH}?address=${search}&language=EN&key=${API_KEY}&limit=${limit}&geographicAddress=false&vanityMode=false&addressElements=false&addressProfileName=&country=IE&_=1525779831819`
  ).then(res => {
    if (res.status >= 400) {
      throw new Error('Bad response from server')
    }
    return res.json()
  })
}

export {addressSearch as default, addressSearch, intAutoAddress};
import { Controller } from 'stimulus'
import isLeapYear from 'date-fns/isleapyear'

export default class extends Controller {
  initialize() {
    this.dateElement = this.element.querySelectorAll('input')
    this.submitButton = this.element.parentElement.parentElement.querySelector(
      '.form-submit-button'
    )
  }

  validateDate(event) {
    const date = this.dateElement[0];
    const month = this.dateElement[1];
    const year = this.dateElement[2];
    const combinedDate = this.dateElement[3] != undefined ? this.dateElement[3] : null;


    //combine the date, month and year
    if (date.value.length >= 2 && date.nextElementSibling) {
      if (event.keyCode !== 16 && event.keyCode !== 9) {
        date.nextElementSibling.focus();
      }
    }


    if (month.value.length >= 2 && month.nextElementSibling) {
      if (event.keyCode !== 16 && event.keyCode !== 9) {
        month.nextElementSibling.focus();
      }
    }


    this.dateofbirth = month.value + '/' + date.value + '/' + year.value

    if (combinedDate != null) {
      if (year.value.length === 4 && month.value.length > 0 && date.value.length > 0) {
        combinedDate.value = new Date(year.value, month.value - 1, date.value, 14, 0, 0, 0);
      }
      else {
        combinedDate.value = "";
      }
    }


    //check it's validity
    if (date.checkValidity() && month.checkValidity() && year.checkValidity()) {
      this.element.querySelector('.date').classList.remove('is-invalid')
      this.element
        .querySelector('.date')
        .nextElementSibling.classList.add('is-hidden')
      const regEx = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9][0-9])*$/
      const valid = regEx.test(this.dateofbirth)
      //regular expression not valid (Incorrect date)
      if (!valid) {
        this.element.querySelector('.date').classList.add('is-invalid')
        this.element.querySelector('.date').classList.remove('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.remove('is-hidden')
        if (this.submitButton !== undefined) {
          this.submitButton.setAttribute('disabled', 'disabled')
        }
      } else if (
        valid &&
        month.value == 2 &&
        isLeapYear(this.dateofbirth) &&
        date.value >= 29
      ) {
        //is leap year and has valid date
        this.element.querySelector('.date').classList.remove('is-invalid')
        this.element.querySelector('.date').classList.add('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.add('is-hidden')
        if (this.submitButton !== undefined) {
          this.submitButton.removeAttribute('disabled', 'disabled')
        }
      } else if (
        valid &&
        month.value == 2 &&
        !isLeapYear(this.dateofbirth) &&
        date.value > 28
      ) {
        //is not leap year
        this.element.querySelector('.date').classList.add('is-invalid')
        this.element.querySelector('.date').classList.remove('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.remove('is-hidden')
        if (this.submitButton !== undefined) {
          this.submitButton.setAttribute('disabled', 'disabled')
        }
      } else {
        this.element.querySelector('.date').classList.remove('is-invalid')
        this.element.querySelector('.date').classList.add('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.add('is-hidden')
        if (this.submitButton !== undefined) {
          this.submitButton.removeAttribute('disabled', 'disabled')
        }
      }
    }
    if (this.element.querySelector('.date').classList.contains('noValidate')) {
      if (date.value === '' && month.value === '' && year.value === '') {
        this.element.querySelector('.date').classList.remove('is-invalid')
        this.element.querySelector('.date').classList.add('is-valid')
        this.element
          .querySelector('.date')
          .nextElementSibling.classList.add('is-hidden')
        if (this.submitButton !== undefined) {
          this.submitButton.removeAttribute('disabled', 'disabled')
        }
      }
    }
  }
}

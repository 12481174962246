import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  initialize() {
    this.callbackForm = document.getElementById('callbackForm')
    this.callbackFormContainer = document.getElementById(
      'callbackFormContainer'
    )
  }

  ctaCallback() {
    if (this.element.classList.contains('offerInfo-content')) {
      var ModalID = this.element.closest('.modal.is-open')
      MicroModal.close(ModalID.id)
      document.querySelector('html').classList.remove('no-scroll')
    }
    document.getElementById('callbackForm').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
    setTimeout(function() {
      this.callbackForm.classList.add('callbackForm-container')
      this.callbackFormContainer.classList.remove('is-hidden')
    }, 1200)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['claimNumber']

  selectChange() {
    this.claimNumberTarget.innerHTML = 'Call ' + event.currentTarget.value
    this.claimNumberTarget.setAttribute(
      'href',
      'tel:' + event.currentTarget.value
    )
  }
}

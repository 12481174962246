const checkAvailable = (route) => {
  let subfolderPath = subfolderPath ? subfolderPath : ''
  const encodedRoute = encodeURIComponent(route)

  return fetch(subfolderPath + '/messenger/queue?route=' + encodedRoute)
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
    .then((data) => data)
    .catch((error) => {
      return error.json().then((body) => {
        console.error(body)
        return { queueID: '', idle: 0, interacting: 0, chatAvailable: false }
      })
    })
}

const getDeployment = () => {
  let subfolderPath = subfolderPath ? subfolderPath : ''
  return fetch(subfolderPath + '/integrationService/messenger/key')
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
    .then((data) => data)
    .catch((error) => {
      return error.json().then((body) => {
        console.error(body)
        return ''
      })
    })
}

const genesysApi = {
  checkAvailable: checkAvailable,
  getDeployment: getDeployment,
}

export { genesysApi as default, genesysApi }

import Prismic from 'prismic-javascript'
import { initApi } from '../utils/prismic'

export const getOrganisations = initApi()
  .then(api => {
    return api.query(Prismic.Predicates.at('document.type', 'organisation'), {
      fetch: ['organisation.name'],
      orderings: '[my.organisation.name] desc',
      pageSize: 1000,
    })
  })
  .then(response => {
    return response.results
  })
  .catch(err => console.log(err))

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['SeeMoreContent']

  initialize() {
    this.benefits_list = this.SeeMoreContentTarget.querySelectorAll('li')
    Array.from(this.benefits_list).forEach((el, i) => {
      if (i < 5) {
        this.benefits_list[i].classList.remove('is-hidden')
      } else {
        this.benefits_list[i].classList.add('is-hidden')
      }
    })
    if (this.benefits_list.length <= 4) {
      this.SeeMoreContentTarget.nextElementSibling.classList.add('is-hidden')
    } else {
      if (
        !this.SeeMoreContentTarget.nextElementSibling.classList.contains(
          'is-hidden'
        )
      ) {
        this.SeeMoreContentTarget.nextElementSibling.classList.remove(
          'is-hidden'
        )
      }
    }
    this.setHeight(document.querySelectorAll('.comparison-list'))
    this.setHeight(document.querySelectorAll('.policy-copy'))
    this.setHeight(document.querySelectorAll('.policyCard-title'))
    this.setHeight(document.querySelectorAll('.policyCard-link-contents'))
    this.setHeight(document.querySelectorAll('.perMonth-copy'))
  }

  toggle(event) {
    event.currentTarget.classList.toggle('is-active')
    //if (event.currentTarget.classList.contains('is-active')) {
    //  event.currentTarget.children[0].innerText = "See less"
    //} else {
    //  event.currentTarget.children[0].innerText = "See more"
    //  event.currentTarget.offsetParent.parentElement.scrollIntoView(true)
    //}
    if (this.benefits_list.length > 5) {
      Array.from(this.benefits_list).forEach((el, i) => {
        if (i >= 5) {
          this.benefits_list[i].classList.toggle('is-hidden')
        }
      })
      this.SeeMoreContentTarget.classList.toggle('comparison-list--show')
      this.SeeMoreContentTarget.classList.toggle('comparison-list--hide')
    }
  }

  setHeight(targetArray) {
    var maxHeight = []
    if (targetArray.length >= 1) {
      Array.from(targetArray).forEach((el, i) => {
        maxHeight = maxHeight > el.scrollHeight ? maxHeight : el.scrollHeight
      })
      Array.from(targetArray).forEach((el, i) => {
        setTimeout(function() {
          el.style.minHeight = maxHeight + 'px'
        }, 1000)
      })
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'FormPhase',
    'DetailsPhase',
    'SuccessForm',
    'FormContentContainer',
  ]
  initialize() {
    this.FormContainer = this.element
  }

  showForm() {
    let callbackFormContainerClassList = document.getElementById(
      'callbackFormContainer'
    ).classList
    let callbackFormClassList = document.getElementById('callbackForm')
      .classList
    if (callbackFormContainerClassList.contains != 'is-hidden') {
      callbackFormContainerClassList.add('is-hidden')
      callbackFormClassList.remove('callbackForm-container')
    }

    this.FormContainer.classList.add('generalEnquiryForm-container')
    this.FormContentContainerTarget.classList.remove('is-hidden')
    if (
      this.element
        .querySelector('.form-container')
        .classList.contains('is-hidden')
    ) {
      this.element
        .querySelector('.form-container')
        .classList.remove('is-hidden')
      this.SuccessFormTarget.classList.add('is-hidden')
    }
  }

  hideForm() {
    document.forms['FooterGeneralEnquiry_Form'].reset()
    this.FormContainer.classList.remove('generalEnquiryForm-container')
    this.FormContentContainerTarget.classList.add('is-hidden')
    var FormElements = this.element.querySelectorAll('input, select, textarea')
    var errorMessageClasses = this.element.querySelectorAll('.status')
    Array.from(FormElements).forEach((el, i) => {
      FormElements[i].oninput = null
      FormElements[i].onchange = null
      if (FormElements[i].classList.contains('is-success')) {
        FormElements[i].classList.remove('is-success')
      }
      if (FormElements[i].classList.contains('is-error')) {
        FormElements[i].classList.remove('is-error')
      }
    })
    Array.from(errorMessageClasses).forEach((el, i) => {
      errorMessageClasses[i].style.display = 'none'
      if (errorMessageClasses[i].classList.contains('status-success')) {
        errorMessageClasses[i].classList.remove('status-success')
      }
      if (errorMessageClasses[i].classList.contains('status-error')) {
        errorMessageClasses[i].classList.remove('status-error')
      }
    })
  }
}

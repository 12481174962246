/* global YT */
import { Controller } from 'stimulus'
// import config from '../config/index'
export default class extends Controller {
  initialize() {
    this.renderUI()
    this.element.addEventListener('click', this.onPlay.bind(this))
  }

  loadAPI(callback) {
    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'

    document.head.appendChild(tag)

    window.onYouTubeIframeAPIReadyCallbacks =
      window.onYouTubeIframeAPIReadyCallbacks || []
    window.onYouTubeIframeAPIReadyCallbacks.push(callback.bind(this))

    window.onYouTubeIframeAPIReady = () => {
      for (var i = 0; i < window.onYouTubeIframeAPIReadyCallbacks.length; i++) {
        window.onYouTubeIframeAPIReadyCallbacks[i].call()
      }
    }
  }

  renderUI() {
    const mediaBtn = `<div class="mediaBtn"><svg class="icon" role="img"><use xlink:href="${
      window.CM_CONFIG.uiPath
    }dist/icons.svg#play"></use></svg></div>`
    this.element.innerHTML = mediaBtn

    this.playerEl = document.createElement('div')
    this.playerEl.classList.add('video-player')
    this.element.appendChild(this.playerEl)

    const imgElSrc = `https://img.youtube.com/vi/${this.data.get(
      'id'
    )}/maxresdefault.jpg`
    this.element.style.backgroundImage = `url(${imgElSrc})`
  }

  createPlayer() {
    this.player = new YT.Player(this.playerEl, {
      height: '100%',
      width: '100%',
      videoId: this.data.get('id'),
      playerVars: {
        autohide: 1,
        autoplay: 1,
        color: 'white',
        controls: 2,
        cc_load_policy: 0,
        disablekb: 0,
        enablejsapi: 1,
        end: null,
        fs: 1,
        iv_load_policy: 3,
        loop: 0,
        modestbranding: 1,
        origin: document.domain, // Should be set to domain
        playsinline: 0,
        rel: 0,
        showinfo: 0,
        start: null,
        theme: 'dark',
        wmode: 'opaque',
        hl: 'en',
      },
      events: {
        onReady: this.onReady.bind(this),
        onStateChange: this.onStateChange.bind(this),
      },
    })
  }

  onReady() {
    this.player.playVideo()
    this.element.querySelector('.mediaBtn').classList.add('is-hidden')
    window.addEventListener('Video:pause', () => {
      this.player.pauseVideo()
    })
    window.addEventListener('Video:stop', () => {
      this.player.stopVideo()
    })
  }

  onStateChange(e) {
    const state = e.data

    if (state === window.YT.PlayerState.PLAYING) {
      this.element.querySelector('.mediaBtn').classList.add('is-hidden')
    }

    if (state === window.YT.PlayerState.PAUSED) {
      this.element.querySelector('.mediaBtn').classList.add('is-hidden')
    }

    if (state === window.YT.PlayerState.ENDED) {
      // clearInterval(this.playerInterval)
      this.element.querySelector('.mediaBtn').classList.add('is-hidden')
      this.close()
    }
  }

  onPlay() {
    if (
      typeof window.YT === 'undefined' ||
      typeof window.YT.Player === 'undefined'
    ) {
      this.waitingForApi = true

      this.loadAPI(() => {
        if (this.waitingForApi) {
          this.createPlayer()
          this.waitingForApi = false
        }
      })
    } else {
      this.createPlayer()
    }
  }

  close() {
    if (this.player) {
      this.player.destroy()
      this.element.querySelector('.mediaBtn').classList.remove('is-hidden')
    }
  }
}

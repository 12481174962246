import Prismic from 'prismic-javascript'
import { initApi } from '../utils/prismic'

export const getOrganisationsSP = initApi()
  .then(api => {
    return api.query(
      Prismic.Predicates.at('document.type', 'organisation_sp'),
      {
        fetch: ['organisation_sp.name'],
        orderings: '[my.organisation_sp.name] desc',
        pageSize: 1000,
      }
    )
  })
  .then(response => {
    return response.results
  })
  .catch(err => console.log(err))

import { Controller } from 'stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  initialize() {
    this.select = this.element.querySelector(
      '.form-controlGroup-inputWrapper--select'
    )
    this.tabsList = this.element.querySelector('.tabs')
    this.threshold = this.tabsList.offsetWidth
    this.calculateDimensions()
    this.hideTabContent()
    window.addEventListener(
      'resize',
      debounce(this.handleWindowResize.bind(this), 300)
    )

    // open default content
    const defaultOpen = this.element.querySelector('#defaultOpen')
    defaultOpen.classList.add('active')

    const defaultTargetTab = defaultOpen.getAttribute('data-tabtarget')
    this.element
      .querySelector(`#${defaultTargetTab}`)
      .classList.remove('is-hidden')

    // create select options dynamically
    const tabID = []
    const tabName = []
    const tabItem = this.element.querySelectorAll('.tabItem')
    Array.from(tabItem).forEach(tab => {
      tabID.push(tab.getAttribute('data-tabtarget'))
      tabName.push(tab.getAttribute('data-tabname'))
    })
    this.createOptions(tabID, tabName)
  }

  handleWindowResize() {
    this.calculateDimensions()
  }

  calculateDimensions() {
    const nav = this.element.querySelector('.tab')

    const viewportWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    if (this.threshold > viewportWidth - 40) {
      nav.classList.add('is-hidden')
      this.select.classList.remove('is-hidden')
    } else {
      nav.classList.remove('is-hidden')
      this.select.classList.add('is-hidden')
    }
  }

  createOptions(tabID, tabName) {
    for (let i = 0; i < tabID.length; i++) {
      let option = document.createElement('option')
      option.text = tabName[i]
      option.value = tabID[i]
      this.select.querySelector('select').add(option)
    }
  }

  tabChange(event) {
    const tabTarget = event.currentTarget.getAttribute('data-tabtarget')
    const currentTab = this.element.querySelector(`#${tabTarget}`)
    this.hideTabContent()
    Array.from(this.element.querySelectorAll('.tabItem')).forEach(item => {
      item.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    currentTab.classList.remove('is-hidden')
    if (
      currentTab.querySelector('.policyCard-content') != null ||
      currentTab.querySelector('.policyCard-content') != undefined
    ) {
      this.setMaxHeight(this.element.querySelectorAll('.comparison-list'))
      this.setMaxHeight(this.element.querySelectorAll('.policy-copy'))
      this.setMaxHeight(this.element.querySelectorAll('.policyCard-title'))
      this.setMaxHeight(
        this.element.querySelectorAll('.policyCard-link-contents')
      )
    }
  }

  selectChange(event) {
    this.hideTabContent()
    Array.from(this.element.querySelectorAll('.tabItem')).forEach(item => {
      if (item.dataset.tabtarget === event.target.value) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
    const currentItem = this.element.querySelector(`#${event.target.value}`)
    currentItem.classList.remove('is-hidden')
    if (
      currentItem.querySelector('.policyCard-content') != null ||
      currentItem.querySelector('.policyCard-content') != undefined
    ) {
      this.setMaxHeight(this.element.querySelectorAll('.comparison-list'))
      this.setMaxHeight(this.element.querySelectorAll('.policy-copy'))
      this.setMaxHeight(this.element.querySelectorAll('.policyCard-title'))
      this.setMaxHeight(
        this.element.querySelectorAll('.policyCard-link-contents')
      )
    }
  }

  hideTabContent() {
    Array.from(this.element.querySelectorAll('.tabContent')).forEach(item => {
      item.classList.add('is-hidden')
    })
  }

  setMaxHeight(targetArray) {
    var maxHeight = []
    if (targetArray.length >= 1) {
      Array.from(targetArray).forEach((el, i) => {
        maxHeight = maxHeight > el.scrollHeight ? maxHeight : el.scrollHeight
      })
      Array.from(targetArray).forEach((el, i) => {
        setTimeout(function() {
          el.style.minHeight = maxHeight + 'px'
        }, 200)
      })
    }
  }
}

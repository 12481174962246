export default [
  {
    name: 's',
    breakpoint: 400,
    unit: 'px',
  },
  {
    name: 'sm',
    breakpoint: 570,
    unit: 'px',
  },
  {
    name: 'm',
    breakpoint: 750,
    unit: 'px',
  },
  {
    name: 'l',
    breakpoint: 1000,
    unit: 'px',
  },
  {
    name: 'xl',
    breakpoint: 1250,
    unit: 'px',
  },
  {
    name: 'xxl',
  },
]

import { Controller } from 'stimulus'
import MicroModal from 'micromodal'

export default class extends Controller {
  initialize() {
    this.targetID = this.data.get('target')
    this.targetElement = document.querySelector(`#${this.targetID}`)

    // If no target element, disable the modal
    if (this.targetElement === null) {
      this.element.setAttribute('disabled', true)
      return
    }

    MicroModal.init({
      // onShow: modal => console.info(`${modal.id} is shown`),
      onClose: () => {
        document.querySelector('html').classList.remove('no-scroll')
        //document.getElementsByName(this.targetID)[0].reset()  //reference
        let forms = document
          .getElementById(this.targetID)
          .querySelector('form')
          .getAttribute('name')
        let formsNode = document.getElementsByName(forms)
        Array.from(formsNode).forEach((form, index) => {
          document.getElementsByName(form.getAttribute('name'))[index].reset()
        })
        document
          .getElementById(this.targetID)
          .querySelector('.message-box')
          .classList.add('is-hidden')
        let ValidationClasses = document
          .getElementById(this.targetID)
          .querySelectorAll('.form-field-valid')
        Array.from(ValidationClasses).forEach((el, i) => {
          ValidationClasses[i].classList.remove('form-field-valid')
        })

        let formtohide = document
          .getElementById(this.targetID)
          .querySelector('.formtohide')
        if (formtohide.classList.contains('is-hidden')) {
          formtohide.classList.remove('is-hidden')
        }
        let callbackForm = document
          .getElementById(this.targetID)
          .querySelector('.genericForm')
        if (!callbackForm.classList.contains('is-hidden')) {
          callbackForm.classList.add('is-hidden')
        }

        let FormElements = document
          .getElementById(this.targetID)
          .querySelectorAll('input, select, textarea')
        let errorMessageClasses = document
          .getElementById(this.targetID)
          .querySelectorAll('.status')
        Array.from(FormElements).forEach((el, i) => {
          FormElements[i].oninput = null
          FormElements[i].onchange = null
          if (FormElements[i].classList.contains('is-success')) {
            FormElements[i].classList.remove('is-success')
          }
          if (FormElements[i].classList.contains('is-error')) {
            FormElements[i].classList.remove('is-error')
          }
        })
        Array.from(errorMessageClasses).forEach((el, i) => {
          errorMessageClasses[i].style.display = 'none'
          if (errorMessageClasses[i].classList.contains('status-success')) {
            errorMessageClasses[i].classList.remove('status-success')
          }
          if (errorMessageClasses[i].classList.contains('status-error')) {
            errorMessageClasses[i].classList.remove('status-error')
          }
        })
      },
      openTrigger: 'data-modalform-target',
      debugMode: false,
    })
    this.element.addEventListener('click', this.handleShow.bind(this))
  }

  handleShow(e) {
    e.preventDefault()
    document.querySelector('html').classList.add('no-scroll')
    MicroModal.show(`${this.targetID}`)
  }
}

/**
 * Project Name: Cornmarket
 * @description The main Cornmarket JS file
 * @author The Nimbletank team
 *
 * Need help using JSDoc? Find out more at http://usejsdoc.org/
 */
import ready from 'lite-ready'
import { Application } from 'stimulus'
import svg4everybody from 'svg4everybody'
import BlockClick from './modules/BlockClick'
import Carousel from './modules/Carousel'
import Modal from './modules/Modal'
import NarrowNav from './modules/NarrowNav'
import Scroll from './modules/Scroll'
import Selector from './modules/Selector'
import ShowMore from './modules/ShowMore'
import Video from './modules/Video'
import WideNav from './modules/WideNav'
import Accordion from './modules/Accordion'
import FAQList from './modules/FAQList'
import FeaturedContentCard from './modules/FeaturedContentCard'
import Tab from './modules/Tab'
import Cookie from './modules/Cookie'
import PolicyCard from './modules/PolicyCard'
import ContactUsCard from './modules/ContactUsCard'
import CallbackForm from './modules/CallbackForm'
import CTAContainer from './modules/CTAContainer'
import Claim from './modules/Claim'
import handRaising from './modules/handRaising'
import jobCount from './modules/jobCount'
import Calculator from './modules/Calculator'
import DateValid from './modules/DateValid'
import SubmitForm from './modules/SubmitForm'
import ModalForm from './modules/ModalForm'
import GeneralEnquiryForm from './modules/GeneralEnquiryForm'
import GetQuote from './modules/GetQuote'
import autoHeight from './modules/autoHeight'
import initGenesys from './genesys'
import '../scss/cornmarket.scss'


ready(() => {
  svg4everybody()
  const application = Application.start()
  application.register('WideNav', WideNav)
  application.register('NarrowNav', NarrowNav)
  application.register('Selector', Selector) // Could be lazy loaded
  application.register('Video', Video)
  application.register('Modal', Modal)
  application.register('BlockClick', BlockClick)
  application.register('Carousel', Carousel)
  application.register('ShowMore', ShowMore)
  application.register('Scroll', Scroll)
  application.register('Accordion', Accordion)
  application.register('FAQList', FAQList)
  application.register('FeaturedContentCard', FeaturedContentCard)
  application.register('Tab', Tab)
  application.register('Cookie', Cookie)
  application.register('PolicyCard', PolicyCard)
  application.register('ContactUsCard', ContactUsCard)
  application.register('CallbackForm', CallbackForm)
  application.register('CTAContainer', CTAContainer)
  application.register('Claim', Claim)
  application.register('handRaising', handRaising)
  application.register('jobCount', jobCount)
  application.register('Calculator', Calculator)
  application.register('DateValid', DateValid)
  application.register('SubmitForm', SubmitForm)
  application.register('ModalForm', ModalForm)
  application.register('GeneralEnquiryForm', GeneralEnquiryForm)
  application.register('GetQuote', GetQuote)
  application.register('autoHeight', autoHeight)

  initGenesys()
})

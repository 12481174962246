import { Controller } from 'stimulus'
import { getOrganisations } from '../api/getOrganisations'
import { getOrganisationsSP } from '../api/getOrgansationsSP'
import fuzzy from 'fuzzy'
import $$ from 'double-dollar'
import ActionOutside from 'action-outside'
import { getZendeskData, initFaq} from '../api/getZendeskData'
import { addressSearch, intAutoAddress} from '../api/addressSearch'

export default class extends Controller {
  static get targets() {
    return [
      'results',
      'datawrapper',
      'input',
      'resultsList',
      'cancel',
      'choice',
      'choiceBtn',
      'baseURL',
    ]
  }

  initialize() {
    this.clearInput()

    // types:
    // zendesk ✅
    // organisations ✅
    // address lookup ✅

    this.items = []
    this.type = this.data.has('type') && this.data.get('type')

    if (this.type === 'organisation') {
      getOrganisations.then(response => {
        const res = response
        const items = res.map(item => {
          return {
            name: item.data.name,
            href: `/union/${item.uid}/`,
          }
        })
        this.items = items

        // Show the list of organisations on the narrow nav without :focus
        if (this.data.has('narrow')) {
          this.handleShow()
        }
      })
    }
    if (this.type === 'organisation-sp') {
      getOrganisationsSP.then(response => {
        const res = response
        const items = res.map(item => {
          return {
            name: item.data.name,
            href: `${item.uid}/`,
          }
        })
        this.items = items

        // Show the list of organisations on the narrow nav without :focus
        if (this.data.has('narrow')) {
          this.handleShow()
        }
      })
    }

    if (this.type === 'address') {
      intAutoAddress();
    }
    if(this.type === 'faq'){
      initFaq();
    }
  }

  renderListHeader(value = '') {
    document.querySelector('.mastheadRoot').classList.add('mastheadRoot-active')
    document
      .querySelector('.dropdownRoot')
      .classList.add('is-active', 'selector-union-active-transition')
    document
      .querySelector('.dropdownSection .unionSearchRoot')
      .parentNode.classList.add('is-active', 'selector-union-active')
    document
      .querySelector('.dropdownSection .unionSearchRoot')
      .parentNode.setAttribute('aria-hidden', 'false')
    document
      .querySelector('.common-search-container')
      .classList.add('is-hidden')
    const list = this.items.map(item => {
      return {
        name: item.name,
        href: item.href,
      }
    })

    const options = {
      pre: '<b>', // Wrap each character in a <b> tag to bold
      post: '</b>',
      extract: item => {
        return item.name
      },
    }

    // Filter!
    const filtered = fuzzy.filter(value, list, options)
    let results

    if (filtered.length === 0) {
      results = [`<div class="selector-results-item">No results</div>`]
    } else {
      results = filtered.map(entry => {
        if (this.data.has('narrow')) {
          return `<li><a class="iconArrowBtn" href="${entry.original.href}">${
            entry.string
          }</a></li>`
        }
        return `<button class="selector-results-item" data-href="${
          entry.original.href
        }">${entry.string}</button>`
      })
    }

    this.resultsListTarget.innerHTML = results.join('')

    this.handleKeydownNavigation = e => {
      const TAB = 9
      const ESC = 27

      if (e.keyCode === TAB) {
        if (!this.data.get('active')) {
          if (value.length) {
            // this.handleShow()
            this.selectItem(
              document.activeElement.textContent,
              document.activeElement.getAttribute('data-href')
            )
          }
        }
      } else if (e.keyCode === ESC) {
        this.handleCancel()
      }
    }

    this.element.addEventListener('keydown', this.handleKeydownNavigation)

    $$('.selector-results-item', this.resultsListTarget).forEach(item => {
      item.addEventListener('click', e => {
        e.preventDefault()
        this.selectItem(
          e.target.textContent,
          e.target.getAttribute('data-href')
        )
      })
    })

    const actionOutsideMenu = new ActionOutside(this.element, () => {
      this.handleHide()
      actionOutsideMenu.listen(false)
    })
    actionOutsideMenu.listen(true)
  }

  renderList(value = '') {
    //console.log('renderList:: this.items', this.items)
    const list = this.items.map(item => {
      return {
        name: item.name,
        href: item.href,
      }
    })

    const options = {
      pre: '<b>', // Wrap each character in a <b> tag to bold
      post: '</b>',
      extract: item => {
        return item.name
      },
    }

    // Filter!
    const filtered = fuzzy.filter(value, list, options)
    let results

    if (filtered.length === 0) {
      results = [`<div class="selector-results-item">No results</div>`]
    } else {
      results = filtered.map(entry => {
        if (this.data.has('narrow')) {
          return `<li><a class="iconArrowBtn" href="${entry.original.href}">${
            entry.string
          }</a></li>`
        }
        return `<button class="selector-results-item" data-href="${
          entry.original.href
        }">${entry.string}</button>`
      })
    }

    this.resultsListTarget.innerHTML = results.join('')

    this.handleKeydownNavigation = e => {
      const TAB = 9
      const ESC = 27

      if (e.keyCode === TAB) {
        if (!this.data.get('active')) {
          if (value.length) {
            // this.handleShow()
            this.selectItem(
              document.activeElement.textContent,
              document.activeElement.getAttribute('data-href')
            )
          }
        }
      } else if (e.keyCode === ESC) {
        this.handleCancel()
      }
    }

    this.element.addEventListener('keydown', this.handleKeydownNavigation)

    $$('.selector-results-item', this.resultsListTarget).forEach(item => {
      item.addEventListener('click', e => {
        e.preventDefault();
        const textContent = e.currentTarget.textContent;
        const opt = this.items.find(i => i.name === textContent);

        const newLocation = this.baseURLTarget.value ? `${'/' + this.baseURLTarget.value + '/' + opt.href}` : opt.href;
        window.location = newLocation;
      })
    })

    const actionOutsideMenu = new ActionOutside(this.element, () => {
      this.handleHide()
      actionOutsideMenu.listen(false)
    })
    actionOutsideMenu.listen(true)
  }

  handleKeyup(event) {
    if (event.target.value.length === 0) return
    switch (this.type) {
      case 'faq':
        this.searchZendesk(event.target.value)
        break
      case 'address':
        this.searchAddress(event.target.value)
        break
      case 'organisation':
        this.renderListHeader(event.target.value)
        break
      case 'organisation-sp':
        this.renderList(event.target.value)
        break
      default:
        console.log('default')
        break
    }

    if (event.target.value.length) {
      this.cancelTarget.classList.add('is-active')
    }
  }

  searchZendesk(input) {
    getZendeskData(`api/v2/help_center/articles/search.json?query=${input}`)
      .then(response => {
        const items = response.results.map(item => {
          return {
            name: item.name,
            href: item.html_url,
          }
        })
        this.items = items
        this.renderList()
        if (this.items.length > 0) {
          this.handleShow()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  searchAddress(input) {
    addressSearch(input)
      .then(response => {
        const items = response.options.map(item => {
          return {
            name: item.displayName,
            href: 'null',
          }
        })
        this.items = items
        this.renderList()
        if (this.items.length > 0) {
          this.handleShow()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  handleFocus() {
    if (this.type === 'organisation') {
      this.handleShow(this.type)
    }
    if (this.type === 'organisation-sp') {
      this.handleShow(this.type)
    }
  }

  handleBlur() {
    if (!this.data.get('active')) {
      this.handleHide()
    }
  }

  handleShow(type) {
    this.data.set('active', true)
    this.datawrapperTarget.classList.add('is-active')
    this.resultsTarget.setAttribute('aria-hidden', 'false')
    if (type === 'organisation') {
      this.renderListHeader()
    }
    this.renderList()
    if (this.data.has('nav')) {
      window.SelectorOpen = true
    }
  }

  handleHide() {
    this.data.set('active', false)
    this.datawrapperTarget.classList.remove('is-active')
    this.resultsTarget.setAttribute('aria-hidden', 'true')
  }

  handleCancel() {
    document
      .querySelector('.dropdownSection .unionSearchRoot')
      .parentNode.setAttribute('aria-hidden', 'true')
    document
      .querySelector('.common-search-container')
      .classList.remove('is-hidden')
    this.handleHide()
    this.clearInput()
    if (this.handleKeydownNavigation !== null) {
      this.unregisterKeyNavigation()
    }
  }

  unregisterKeyNavigation() {
    this.element.removeEventListener('keydown', this.keyDownHandler)
    this.keyDownHandler = null
  }

  updateInputValue(value) {
    if (value == 'No results') {
      this.choiceBtnTarget.style.display = 'none'
    } else {
      this.inputTarget.value = value
      this.choiceBtnTarget.style.display = 'inline-block'
    }
  }

  clearInput() {
    this.inputTarget.value = ''
    this.cancelTarget.classList.remove('is-active')
    this.inputTarget.classList.remove('is-active')
    if (this.hasChoiceTarget) {
      this.choiceTarget.classList.remove('is-active')
    }
  }

  selectItem(value, href) {
    this.cancelTarget.classList.add('is-active')
    this.inputTarget.classList.add('is-active')
    if (this.hasChoiceTarget && this.baseURLTarget.value) {
      this.choiceTarget.classList.add('is-active')
      this.choiceBtnTarget.setAttribute(
        'href',
        `${'/' + this.baseURLTarget.value + '/' + href}`
      )
    } else {
      this.choiceTarget.classList.add('is-active')
      this.choiceBtnTarget.setAttribute('href', `${href}`)
    }

    this.updateInputValue(value)
    this.handleHide()

    if (this.type === 'address') {
      this.sendAddressData(value)
    }
  }

  sendAddressData(data) {
    console.log('TODO: send this data to another page')
    console.log(data)
  }
}

document.addEventListener('click', evt => {
  const flyoutElement = document.getElementById('unionSelector')
  let targetElement = evt.target

  do {
    if (targetElement == flyoutElement) {
      return
    }
    targetElement = targetElement.parentNode
  } while (targetElement)

  document
    .querySelector('.mastheadRoot')
    .classList.remove('mastheadRoot-active')
  document
    .querySelector('.dropdownRoot')
    .classList.remove('is-active', 'selector-union-active-transition')
  document
    .querySelector('.dropdownSection .unionSearchRoot')
    .parentNode.classList.remove('is-active', 'selector-union-active')
  document
    .querySelector('.dropdownSection .unionSearchRoot')
    .parentNode.setAttribute('aria-hidden', 'true')
  document
    .querySelector('.common-search-container')
    .classList.remove('is-hidden')
})

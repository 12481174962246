import { Controller } from 'stimulus'
import getZendeskData, { API_PATH } from '../api/getZendeskData'

export default class extends Controller {
  initialize() {
    this.section = this.data.get('section')
    this.getData()
  }

  getData() {
    getZendeskData(
      `api/v2/help_center/en-us/sections/${this.section}/articles.json`
    )
      .then(data => {
        this.renderMarkup(data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  renderMarkup(data) {
    const articles = data.articles.slice(0, 4) // only get the first 4 items
    const accordionMarkup = articles
      .map(item => {
        return `<h3 class="accordion-title" data-target="Accordion.Title"
      data-action="click->Accordion#handleChange">
        ${item.title}
        <span class="accordion-icon"></span>
      </h3>
      <div class="accordion-body accordion-body--hide" data-target="Accordion.Body">
        ${item.body}
        <div>
          <a href="${item.html_url}" class="arrowLink">Read full article</a>
        </div>
      </div>`
      })
      .join('')

    const markup = `<div class="accordion" data-controller="Accordion">
      ${accordionMarkup}
    </div>
    <br>
    <a href="https://${API_PATH}.zendesk.com/hc/en-us/sections/${
      this.section
    }" class="btn btn--secondary">Go to FAQs</a>
    `

    this.element.innerHTML = markup
  }
}

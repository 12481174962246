import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.element.classList.add('is-active')

    window.addEventListener('DOMContentLoaded', function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })
  }

  hideOverlay(event) {
    this.element.classList.remove('is-active')
  }

  redirectPage(e) {
    window.location = e.target.value
  }
}

/*global Genesys:readonly */
import genesysApi from './genesysApi'
import getRoute from './getRoute'
import { isfunction } from '../utils/istype'
import addCookieBotListeners from '../utils/cookiebotListeners'

let isOpen = { value: 'false' }
let key = ''
let listenersAdded = false

const initGenesys = () => {
  getLocalStorage()
  if (typeof Genesys !== 'undefined' && isfunction(Genesys)) {
    launchGenesys()
    subscribeGenesysOpen()
  } else if (!listenersAdded) {
    listenersAdded = addCookieBotListeners({
      OnDecline: recallInit,
      OnAccept: recallInit,
      listenerFlag: listenersAdded,
    })
  }
}

const recallInit = () => {
  setTimeout(() => initGenesys(), 100)
}

const getLocalStorage = () => {
  genesysApi.getDeployment().then((response) => {
    if (response != '') {
      isOpen = JSON.parse(localStorage.getItem(`_${response}:gcmcopn`))
    }
  })
}

const setRoute = () => {
  Genesys('command', 'Database.set', {
    messaging: {
      customAttributes: {
        url: key,
      },
    },
  })
}

const launchGenesys = () => {
  Genesys('subscribe', 'Launcher.ready', () => {
    let route = getRoute()
    genesysApi.checkAvailable(route).then((response) => {
      key = response.key
      if (response?.chatAvailable) {
        Genesys('command', 'Launcher.show')
      } else {
        if (isOpen?.value == 'true') {
          Genesys('command', 'Messenger.close')
        }
        Genesys('command', 'Launcher.hide')
      }
    })
  })
}

const subscribeGenesysOpen = () => {
  Genesys('subscribe', 'Messenger.opened', function () {
    setRoute()
  })
}

export { initGenesys as default, initGenesys }

import { Controller } from 'stimulus'

const MOTW_PATH = window.CM_CONFIG.apis.motwUrl.endpoint
const HOTW_PATH = window.CM_CONFIG.apis.hotwUrl.endpoint

export default class extends Controller {
  initialize() {
    this.RegisterNumber = this.element.querySelector(
      '.form-input--carRegistrationHeroCard'
    )
    this.HomeAddress = this.element.querySelector('.selector-input')
  }

  getMotorQuote() {
    if (this.RegisterNumber.value !== '') {
      // window.location = "http://localhost:52003/your-car/?" + this.RegisterNumber.value
      window.location = `${MOTW_PATH}/your-car/?` + this.RegisterNumber.value
    }
  }

  getHomeQuote() {
    if (this.HomeAddress.value !== '') {
      // window.location = "http://localhost:52002/address/?" + this.HomeAddress.value
      window.location = `${HOTW_PATH}/address/?` + this.HomeAddress.value
    }
  }
}
